
body {
  margin: 0;
  height: 100vh;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

button {
  font-size: 14px !important;
}
